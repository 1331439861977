<template>
  <div>
    <label for="know-from">* {{ $t("moderation.from_where") }}</label>
    <textarea id="know-from" v-model="knowFrow" maxlength="250" cols="2" rows="4"></textarea>
    <label for="traffic" class="mt-4">* {{ $t("moderation.source_traffic") }}</label>

    <textarea id="traffic" v-model="trafficSource" maxlength="1000" cols="30" rows="10"></textarea>
    <label for="screenshots" class="mt-4">* {{ $t("moderation.add_screenshots") }}</label>

    <div class="upload-area">
      <div class="placeholder" v-if="files.length !== 5" :style="{ order: !files.length ? 0 : 1 }">+</div>
      <div class="upload-info" v-if="files.length === 0">
        <h4>{{ $t("moderation.drag_here") }}</h4>
        <span class="d-block pt-2">{{ $t("moderation.max_limit") }} <br />{{ $t("moderation.max_size") }}</span>
        <span class="d-block pt-2">{{ $t("moderation.uploaded") }}: {{ files.length }} / 5</span>
      </div>
      <template v-if="files.length > 0">
        <div class="position-relative" v-for="(image, index) in files" :key="index">
          <span class="remove" @click="remove(index)">
            <svg v-svg role="presentation" size="0 0 18 16" symbol="x"></svg>
          </span>
          <img :src="image" class="dropped-image" />
        </div>
      </template>

      <input :disabled="files.length === 5" type="file" class="upload" multiple @change="selectFile($event)" />
    </div>

    <button @click.prevent="send" :disabled="!knowFrow || !trafficSource || !files.length" class="moderation_btn mt-3 btn _mob100">
      {{ $t("save") }}
    </button>
    <div class="sep"></div>
    <alert v-if="$alert.title" />
  </div>
</template>

<script>
import ServicePanel from "@/api/servicePanel";
import { mapActions } from "vuex";

export default {
  data: () => ({
    knowFrow: "",
    trafficSource: "",
    files: [],
    mediaFiles: [],
  }),

  methods: {
    ...mapActions({
      setUser: "user/setUser",
    }),
    selectFile(e) {
      if (this.files.length === 5) return;

      const file = e.target.files[0];

      if (!/\.(jpg|jpeg|png|JPG|PNG)$/.test(e.target.value)) {
        this.$alert({
          title: this.$t("sent_error"),
          text: this.$t("allowed_formats") + " .jpeg, jpg, png",
        });
        return;
      }

      if (file.size > 5000000) {
        this.$alert({
          title: this.$t("sent_error"),
          text: this.$t("max_size_file") + " 5 Mb",
        });
        return;
      }

      this.mediaFiles.push(file);
      this.files.push(URL.createObjectURL(file));
    },
    remove(index) {
      document.querySelector(".upload").value = "";
      this.files.splice(index, 1);
    },
    send() {
      let formData = new FormData();
      formData.append("from", this.knowFrow);
      formData.append("traffic_source", this.trafficSource);

      for (let i = 0; i < this.files.length; i++) {
        formData.append(`filename${i + 1}`, this.mediaFiles[i]);
      }

      ServicePanel.sendForm(formData).then((res) => {
        if (res && res.status === 200) {
          this.$alert({
            title: this.$t("sent_success"),
            text: res.message,
            type: "success",
          });
          this.$emit("send", true);
          this.setUser();
        } else {
          this.$alert({
            title: this.$t("sent_error"),
            text: res.data?.files || res.data?.from || res.data?.trafficSource,
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
textarea#know-from {
  height: 100px;
}

.upload-area {
  background: #f7f7f9;
  border-radius: 10px;
  display: flex;
  column-gap: 10px;
  padding: 20px;
  border: 1px dashed #b0b0bb;

  .upload-info {
    h4 {
      font-weight: 400;
    }
    span {
      font-size: 14px;
      color: #afafaf;
    }
  }
  &.disable {
    cursor: not-allowed;
  }

  .dropped-image {
    height: 100px;
    width: 100px;
    border-radius: 10px;
    object-fit: cover;
  }
  span.remove {
    position: absolute;
    background: #cb5d5d;
    cursor: pointer;
    right: -2px;
    top: -2px;
    padding: 2px 8px;
    border-radius: 50px;
    z-index: 5;
    svg {
      fill: white;
      width: 10px;
    }
  }

  .placeholder {
    border: 1px solid #e2e2e7;
    font-size: 40px;
    width: 100px;
    height: 100px;
    border-radius: 10px;
    color: #b0b0bb;
    align-items: center;
    justify-content: center;
    display: flex;
  }

  input.upload {
    position: absolute;
    width: 100%;
    height: 115px;
    left: 0;
    opacity: 0;
  }
}

input.upload:hover + .upload-area {
  border: 5px dashed #b0b0bb !important;
}

.moderation_btn {
  min-width: 200px;
}
</style>
